<template>
  <div class="resourceSet">
    <div class="title">
      <span>资源配置</span>
    </div>
    <el-card class="condition_style2">
      <el-table :data="tableData2" style="width: 100%" class="table" row-key="id" ref="tabletree"
        @select-all="selectAll" :select-on-indeterminate="true" :tree-props="{children: 'children'}"
        :default-expand-all="false" :height="tableHeight">
        <!-- <el-table-column  type="selection" width="50" label="全选" align="center" ></el-table-column> -->
        <el-table-column :min-width="item.width" align="center" v-for="(item,index) in tableList" :key="index"
          :label="item.label" :prop="item.prop"></el-table-column>
        <!-- <el-table-column prop="orderNum" label="编辑排序" min-width="200" align="center"
          :render-header="renderHeader">
          <template slot-scope="scope">
            <el-input v-if="showBtn" v-model="scope.row.orderNum"
              @input="scope.row.orderNum=$util.isNumber0(scope.row.orderNum)" placeholder="请输入序号" maxlength="3">
            </el-input>
            <span v-if="!showBtn">{{scope.row.orderNum}}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="center" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button @click="addClick(scope.row)" type="text" size="mini"
              :disabled="(scope.row.model||scope.row.configName)?false:true">新增</el-button>
            <el-button @click="deleteItem(scope.row)" :disabled="(scope.row.configType)?false:true" type="text"
              size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"></pagination> -->
    </el-card>
    <!-- 新增分类-->
    <modal :obj="{
        title: '新增分类',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <!-- <el-form-item label="平台名称：" prop="mchName">
          <el-input v-model="addForm.mchName" disabled placeholder="请输入平台名称"></el-input>
        </el-form-item> -->
        <el-form-item label="板块: " prop="model">
          <el-input v-model="addForm.model" disabled placeholder="请输入板块"></el-input>
        </el-form-item>
        <el-form-item label="分类：" prop="configName">
          <el-input v-model="addForm.configName" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="适读人群：" prop="adaptorType">
          <el-select class="w_100" v-model="addForm.adaptorType" placeholder="请选择适读人群">
            <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </modal>
    <!-- 新增资源名称 -->
    <modal :obj="{
        title: '新增资源',
        dialog: resourceDialog,
        dialogText: 'resourceDialog',
        form: resourForm,
        ref: 'resourForm',
        width:'1200px',
        height:'500px',
        okText:'添加'
      }" @submit="resourSubmit">

      <el-row :gutter="24">
        <el-col :span="18">
          <el-form :model="resourForm" :inline="true" class="flex" label-width="130px" ref="resourForm">
            <el-form-item>
              <el-input v-model="resourForm.title" placeholder="请输入标题名称" suffix-icon="el-icon-search"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getThemetypeList()">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="resourceList" style="width: 100%" class="table" :height="300">
            <el-table-column type="index" label="序号" width="60" align="center" fixed>
              <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
            </el-table-column>
            <el-table-column prop="title" label="资源标题" min-width="150" align="center">
              <template slot-scope="scope">
                <div>{{scope.row.topicId?scope.row.topicName:scope.row.courseId?scope.row.courseName:scope.row.title}}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="summary" label="摘要" min-width="200" align="center" :show-overflow-tooltip="true">
           <template slot-scope="scope" v-if="scope.row.title">
              <div class='myNote' v-html="scope.row.title"></div>
          </template>
        </el-table-column> -->
            <el-table-column prop="typeName" label="所属分类" min-width="150" align="center">
              <template slot-scope="scope">
                <div>
                  {{scope.row.topicId?scope.row.psyTypeName:scope.row.courseId?scope.row.courseTypeName:scope.row.typeName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="adaptorType" label="适读人群" min-width="100" align="center">
              <template slot-scope="scope">
                <div>{{scope.row.adaptorType==3?"学生":scope.row.adaptorType==4?"家长":"所有"}}</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="130" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.fReturnGoodsCheck" @change="tableCheckboxChange(scope.row)">
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"></pagination>
        </el-col>
        <el-col :span="6">
          <div class="tagTitle">已选</div>
          <el-tag class="tag" style="width: 100%!important; margin-bottom: 20px;" size="medium"
            v-for="(item , index) in tags" :key="index" @close="changeClose(item,index)"> {{item.title}}</el-tag>
        </el-col>
      </el-row>


    </modal>
  </div>
</template>

<script>
  import pagination from "../../components/pagination";
  import modal from "../../components/modal";
  import {
    missionExamList,
    topicList,
    getCourseList,
    ConfigSave,
    mchCloudItemSave,
    mchCloudItemDel,
    ConfigDel,
    mchCloudConfigList,
  } from "../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        isAllSelect: false,
        // 全选标志
        checkAll: false,
        // 半选标志
        tableIsIndeterminate: false,
        tags: [],
        resourForm: {
          title: "",
        },
        current: 1,
        size: 10,
        total: 0,
        id: "",
        mchId: "",
        multipleSelection: [],
        blackList: [{
          id: 3,
          name: '学生'
        }, {
          id: 4,
          name: '家长'
        }],
        tableList: [{
            width: "250",
            label: "平台ID",
            prop: "mchId"
          },
          {
            width: "130",
            label: "平台名称",
            prop: "mchName"
          },
          {
            width: "130",
            label: "板块",
            prop: "model"
          },
          {
            width: "130",
            label: "分类",
            prop: "configName"
          },
          {
            width: "130",
            label: "资源名称",
            prop: "resourceName"
          },
          {
            width: "130",
            label: "文件格式",
            prop: "state"
          },
          {
            width: "130",
            label: "最后操作时间",
            prop: "time",
          },
          {
            width: "130",
            label: "最后操作人",
            prop: "updater"
          }
        ],
        tableData2: [],
        showBtn: false,
        searchForm: {
          page: 1,
          limit: 10,
          name: "",
          type: null,
          status: 0
        },
        total: 0,
        tableHeight: this.$util.getHeight(210),
        tableData: [],
        resourceList: [],
        typeList: [],
        addDialog: false,
        resourceDialog: false,
        edit: "",
        resourForm: {

        },
        skuType: "",
        addForm: {
          mchId: "",
          configName: "", // 板块
          configType: "", //分类
          mchName: "", //平台名称
          skuType: "", //资源
          adaptorType: null
        },
        rules: {
          mchName: [{
            required: true,
            message: "请输入平台名称"
          }],
          configName: [{
            required: true,
            message: "请选择所属分类"
          }],
          configType: [{
            required: true,
            message: "请选择板块"
          }],
          adaptorType: [{
            required: true,
            message: "请选择适读人群"
          }],

        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.getTableHeight(330)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      //列表批量全选
      selectAll(selection, first) {
        if (!first) {
          this.isAllSelect = !this.isAllSelect;
        }
        selection.map(el => {
          if (el.children) {
            el.children.map(j => {
              this.toggleSelection(j, this.isAllSelect);
            });
            if (el.children.length > 0) {
              this.selectAll(el.children, true);
            }
          }
        });
      },
      //列表勾选
      toggleSelection(row, select) {
        if (select) {
          this.$refs.tabletree.toggleRowSelection(row, select);
        } else {
          this.$refs.tabletree.clearSelection();
        }
      },
      //资源新增勾选
      tableCheckboxChange(val) {
        setTimeout(() => {
          this.resourceList.every(
            item => item.fReturnGoodsCheck === true

          );
          this.resourceList.every(item =>
            item.fReturnGoodsCheck === false

          );
        }, 0);
        if (val.fReturnGoodsCheck === true) {
          this.tags.push({
            id: val.id ? val.id : val.topicId ? val.topicId : val.courseId,
            title: val.topicId ? val.topicName : val.courseId ? val.courseName : val.title
          })
        } else {
          if (this.tags && this.tags.length) {
            this.tags.forEach((i, key) => {
              if (val.id == i.id) {
                this.tags.splice(key, 1)
              }
            })
          }
        }
      },
      //删除标签
      changeClose(item, index) {
        this.$forceUpdate()
        this.tags.splice(index, 1)

      },
      //删除
      deleteItem(val) {
        let id = val.id.substring(val.id.lastIndexOf("_") + 1);
        let url = val.resourceName ? mchCloudItemDel : ConfigDel
        let params1 = {
          itemId: id
        }
        let params2 = {
          configId: id
        }
        this.$util.isSwal("确定删除？", () => {
          this.$request(url, val.resourceName ? params1 : params2).then((res) => {
            if (res.code === 0) {
              let data = res.data;
              this.$util.message("suc", "删除成功")
              this.getList()

            }
          });
        }, val);
      },
       //   自定义表头
      renderHeader() {
        return this.showBtn ? (
          <div>
            <div class="editTd">编辑排序</div>
            <div class="sort_tips">正整数越小越靠前</div>
            <el-button
              type="primary"
              size="small"
              on-click={() => this.editSort()}
            >
              保存
            </el-button>
            <el-button
              size="small"
              on-click={() => ((this.showBtn = false), this.getList())}
            >
              取消
            </el-button>
          </div>
        ) : (
          <div>
            <span class="editTd" on-click={() => (this.showBtn = true,this.getTableHeight(250))}>
              编辑排序
            </span>
          </div>
        );
      },
      //新增
      addClick(val) {
        this.edit = val;
        this.skuType = val.configType
        console.log(val, "val,")
        this.resourceList = []
        if (!val.model) {
          this.resourceDialog = true;
          this.tags = []
          this.getThemetypeList()
        } else {
          this.addDialog = true;
        }
        let configType = val.id.substring(val.id.lastIndexOf("_") + 1);
        let mchId = val.id.substring(0, val.id.indexOf("_"));
        // console.log(id,"id")
        this.addForm = {
          mchId: mchId,
          configName: "",
          id: val.id ? val.id : "",
          mchName: val.mchName ? val.mchName : "",
          model: val.model ? val.model : "",
          configType: configType ? configType : "",
          adaptorType: null,
        }

      },
      //获取科普资源列表
      getThemetypeList() {
        let mchId = this.edit.id.substring(0, this.edit.id.indexOf("_"));
        let url = ""
        let data = {
          typeTheme: this.edit.configType,
          mchId: mchId,
          courseName: this.resourForm.title,
          adaptorType: this.edit.adaptorType
        }
        switch (this.edit.configType) {
          case 1:
            url = missionExamList;
            data.title = this.resourForm.title
            break;
          case 4:
            url = topicList;
            data.topicName = this.resourForm.title
            break;
          case 5:
            url = getCourseList;
            break;
          case 6:
            url = getCourseList;
            break;
        };
        this.$request(url(this.current, this.size), data).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.resourceList = data.records;
            this.total = Number(data.total);
          }
        });
      },

      //资源新增
      resourSubmit() {
        let skuIds = []
        this.tags.forEach((item) => {
          skuIds.push(item.id)
        })
        this.$request(mchCloudItemSave, {
          configId: this.addForm.configType,
          skuIds: skuIds,
          skuType: this.skuType
        }).then((res) => {
          if (res.code == 0) {
            this.resourceDialog = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.$request(mchCloudConfigList).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            if (data && data.length > 0) {
              data.forEach((i, v) => {
                i.id = v + 1
                i.children = i.nodes
                if (i.nodes && i.nodes.length) {
                  i.nodes.forEach((val, index) => {
                    val.children = val.nodes
                    if (val.nodes && val.nodes.length) {
                      val.nodes.forEach((item, t) => {
                        item.children = item.nodes
                        if (item.nodes && item.nodes.length) {
                          item.nodes.forEach((a, c) => {
                            a.children = a.nodes
                          })
                        }
                      })
                    }
                  })
                }
              });
            }
            this.tableData2 = data;
            console.log(data, "this.tableData2this.tableData2")
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(ConfigSave, this.addForm).then((res) => {
              if (res.code == 0) {
                if (res.data) {
                  this.addDialog = false;
                  this.$util.message("suc", "操作成功");
                  this.getList();
                }
              }
            });
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getThemetypeList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getThemetypeList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .resourceSet {
    .w_48_l {
      width: 60%;
      display: inline-block;
    }

    .condition_style2 {

      /deep/.el-table td,
      .el-table th {
        padding: 0;
      }
    }

    .tagTitle {
      height: 40px;
      font-size: 20px;

      .tag {
        // width:50px;
        // margin:20px 0;

      }

      /deep/.el-tag {
        // width: 79px!important;
        margin-bottom: 20px;
        display: block;
      }
    }

    .w_48 {
      width: 40%;
      display: inline-block;

      /deep/.el-form-item__content {
        margin-left: 12px !important;
      }

    }
  }
</style>